import React from "react";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";

const ContactCard = (props) => {
  return (
    <div className="wr-contact-card">
      {/* <div className="wr-banner">
        <img
          className="img-banner"
          src={props.image}
          alt="contact card image"
        />
        <h4 className="title-2 my-2">{props.hubname}</h4>
        <div className="job-location">
          <FaMapMarkerAlt />
          <p className="mb-0">{props.location}</p>
        </div>
      </div> */}
      <h4 className="title-2 mt-2">{props.hubname}</h4>
      <p className="">{props.location}</p>
        {/* <div className="job-location">
          <FaMapMarkerAlt />
          <p className="mb-0">{props.location}</p>
        </div> */}
      <div className="office-info-side">
        {props.email && (
          <div className="contact-info-group">
            <div className="icon-side">
              <FaEnvelope />
            </div>

            <div className="data-side">
              <label>Email:</label>
              <p>{props.email}</p>
            </div>
          </div>
        )}
        {props.phonenumber1 && (
          <div className="contact-info-group">
            <div className="icon-side">
              <FaPhoneAlt />
            </div>

            <div className="data-side">
              <label>Phone:</label>
              <p>{props.phonenumber1}</p>
              <p>{props.phonenumber2}</p>
            </div>
          </div>
        )}
        {props.address && (
          <div className="contact-info-group">
            <div className="icon-side">
              <FaMapMarkerAlt />
            </div>

            <div className="data-side">
              <label>Location:</label>
              <p>{props.address}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ContactCard;
