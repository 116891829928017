import React from "react";
import LinkedIn from "../images/icons/linkedin.svg";
import SvgIconsSet from "../components/SvgIconsSet";

const UserCard = (props) => {
  return (
    <div className="wr-user-card-main">
      <div className="wr-user-card">
        <div className="user-img">
          <img src={props.image} alt="user card image" />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between w-100 mt-2">
        <div className="wr-user-info">
          <div className="user-name">{props.name}</div>
          <div className="d-flex align-items-center">
          <div className="user-detail">{props.detail}</div>
          {/* <div className="socialmedia ml-1"><a target="_blank" href={props.link}><img src={LinkedIn}/></a></div> */}
          </div>
        </div>
        {/* <div>
          <button className="contact-link"> 
            <span className="btn-icon">
              <SvgIconsSet.SideArrow />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default UserCard;
