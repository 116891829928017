import React from "react";
const IntroBanner = (props) => {
  return (
    <div className="intro intro-banner" id="up">
      <div className="intro-banner-img">
        <img src={props.image} alt="intro banner image" />
      </div>  
        <div className="container">
          <div className="row main-page">
            <div className="col-lg-12">
              <h1 className="page-title">{props.title}</h1>
              {/* <hr /> */}
              <h2 className="page-subtitle">{props.subtitle}</h2>
              <h2 className="page-subtitle">{props.subtitle2}</h2>

            </div>

            {/* <div className="col-lg-5">
              <div className="intro-banner-img">
                <img src={props.image} alt="intro banner image" />
              </div>
            </div> */}
          </div>
        </div>
    </div>
  );
};

export default IntroBanner;
