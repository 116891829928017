import React from "react";

const ValueCard = (props) => {
  return (
    <div className="col-12 col-lg-4 mb-4">
        <div className="wr-value-card">
        <div className="value-title">{props.title}</div>
            <div className="value-detail">{props.detail}</div>
        </div>
      </div>
  );
};

export default ValueCard;
