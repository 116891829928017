import React from "react";

import A1 from '../images/home-page/logos-png/A1.png';
import Beforepay from '../images/home-page/logos-png/Beforepay.png';
import effi from '../images/home-page/logos-png/effi.png';
import oxygen from '../images/home-page/logos-png/oxygen.png';
import pokitpal from '../images/home-page/logos-png/pokitpal.png';

import Lilardia from '../images/home-page/logos-png/Lilardia.png';
import StideEquity from '../images/home-page/logos-png/StideEquity.png';
import Utransport from '../images/home-page/logos-png/Utransport.png';
import C2zero from '../images/home-page/logos-png/C2zero.png';
import GigaClear from '../images/home-page/logos-png/GigaClear.png';
import Symbox from '../images/home-page/logos-png/Symbox.png';
import iGoDirect from '../images/home-page/logos-png/iGoDirect.png';
import Mable from '../images/home-page/logos-png/Mable.png';

import Simble from '../images/home-page/logos-png/Simble.png';
import Solcast from '../images/home-page/logos-png/Solcast.png';
import inovett from '../images/home-page/logos-png/inovett.png';
import possibl from '../images/home-page/logos-png/possibl.png';
import projuno from '../images/home-page/logos-png/projuno.png';

const TrustedClients = (props) => {
  return (
    <div className="img-grid ">
          <div className="card">
          <a href="https://a1spareparts.com.au/" target="_blank">
                  <img className="img-partner" alt="Partners" src={A1} /> </a>
                </div>
          <div className="card">
            <a href="https://www.beforepay.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Beforepay} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.effi.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={effi} />
            </a>
          </div>
          <div className="card">
            <a href="https://oxygen.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={oxygen} />
            </a>
          </div>
          <div className="card">
            <a href="https://pokitpal.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={pokitpal} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.lilardiacapital.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Lilardia} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.strideequity.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={StideEquity} />
            </a>
          </div>
          <div className="card">
            <a href="https://utransport.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Utransport} />
            </a>
          </div>
          <div className="card">
          <a href="https://www.c2zero.net/" target="_blank">
                  <img className="img-partner" alt="Partners" src={C2zero} /></a>
                </div>
          <div className="card">
            <a href="https://gigaclear.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={GigaClear} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.symbox.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Symbox} />
            </a>
          </div>
          <div className="card">
            <a href="https://igodirect.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={iGoDirect} />
            </a>
          </div>
          <div className="card">
            <a href="https://mable.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Mable} />
            </a>
          </div>
          <div className="card">
            <a href="https://simblegroup.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Simble} />
            </a>
          </div>
          <div className="card">
          <a href="https://solcast.com/" target="_blank">
                  <img className="img-partner" alt="Partners" src={Solcast} /> </a>
                </div>
          <div className="card">
            <a href="https://www.inovett.co.uk/" target="_blank">
              <img className="img-partner" alt="Partners" src={inovett} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.possibl.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={possibl} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.projuno.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={projuno} />
            </a>
          </div>
        </div>
  );
};

export default TrustedClients;
