import React from "react";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Banner from "../../images/about-us/about-banner.jpg";

//Headshots
import Indaka from "../../images/about-us/Indaka.jpg";
import Ravi from "../../images/about-us/Ravi.jpg";
import Cam from "../../images/about-us/Cam.jpg";
import Rashan from "../../images/about-us/Rashan.jpg";
import Malinda from "../../images/about-us/MalindaS.jpg";
import Garima from "../../images/about-us/Garima.png";
import Shiraz from "../../images/about-us/Shiraz.jpg";
import Jigar from "../../images/about-us/Jigarjit.jpg";

//Section Images
import about1 from "../../images/about-us/about1.jpg";
import about2 from "../../images/about-us/about2.jpg";
import about3 from "../../images/about-us/about3.jpg";
import about5 from "../../images/about-us/about5.jpg";

//Contact us banners
import headoffice from "../../images/aussi.jpg";
import colombohub from "../../images/colombo.jpg";
import indiahub from "../../images/India New.jpg";
import UKhub from "../../images/UK-office.jpg";

import GetInTouchbtn from "../../components/GetInTouchbtn";
import IntroBanner from "../../components/IntroBannerRevamp";
import UserCard from "../../components/UserCard";
import ContactCard from "../../components/ContactCard";
import Clients from "../../components/TrustedClients";
import ValueCard from "../../components/ValueCard";
import OurPartners from "../../components/OurPartners";

const About = (props) => (
  <Layout bodyClass="about-us">
    <Seo
      title="About Us"
      description="iTelaSoft is an Australian-owned company providing IT services and
              software product development.. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
    />
    <IntroBanner
      title="About Us"
      subtitle="Australian based with global experience, we thrive on innovation and delivering real value to our customers"
      subtitle2="The iTelaSoft team are driven by our passion to solve business problems with technological innovation."
      image={Banner}
    />
    <div className="content">
      <section>
        <div className="container">
          <div className="row pt-6 pb-3">
            <div className="col-12">
              <h2 className="title-1">iTelaSoft Story</h2>
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-lg-6 pb-3 pb-lg-0 d-flex flex-column justify-content-center pr-lg-5">
              <img src={about1} alt="About Us" />
            </div>
            <div className="col-lg-6">
              <h2 className="title-1">Our Vision</h2>
              <p>
                Our vision is to enable organisations of all sizes to leverage
                the latest technological advancements to create:
              </p>
              {/* <ul className="branded-list">
                <li className="mb-3">
                  <i />
                  <h5>Bespoke products & solutions</h5>
                </li>
                <li className="mb-3">
                  <i />
                  <h5>Design industry leading tech frameworks</h5>
                </li>
                <li className="mb-3">
                  <i />
                  <h5>Optimise existing tech stacks</h5>
                </li>
                <li className="mb-3">
                  <i />
                  <h5>Enhance business process & operations</h5>
                </li>
                <li className="mb-3">
                  <i />
                  <h5>Uncover business insights buried deep in system data</h5>
                </li>
              </ul> */}
              <ul className="custom-list-1">
									<li className="mb-2"><h5>Bespoke products & solutions</h5></li>
									<li className="mb-2"><h5>Design industry leading tech frameworks</h5></li>
									<li className="mb-2"><h5>Optimise existing tech stacks</h5></li>
                  <li className="mb-2"><h5>Enhance business process & operations</h5></li>
                  <li className="mb-2"><h5>Uncover business insights buried deep in system data</h5></li>
							</ul>
            
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-lg-6 pb-3 pb-lg-0 d-flex flex-column justify-content-center pr-lg-5">
              <h2 className="title-1">Since 2009</h2>
              <p>
                We&apos;ve been leading the way in innovative technology design
                & delivery since 2009 and have a proven track record of success.
                We work closely with you to understand your goals and adapt our
                solutions to perfectly match your needs.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={about2} alt="About Us" className="img-fluid" />
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-lg-6 pb-3 pb-lg-0 d-flex flex-column justify-content-center pr-lg-5">
              <img src={about5} alt="About Us" />
            </div>
            <div className="col-lg-6">
              <h2 className="title-1">Unique and Highly Skilled Workforce</h2>
              <p>
                With our Head Office and Executive Team based in Sydney
                Australia, we have scoured the globe to build a unique and
                highly skilled workforce comprised of:
              </p>
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                  <div>
                    <h1 className="highlighted-text">150+</h1>
                    <h3 className="title-3">all under one roof</h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <ul className="branded-list">
                    <li className="mb-3">
                      <i />
                      <h5>Strategists</h5>
                    </li>
                    <li className="mb-3">
                      <i />
                      <h5>Architects</h5>
                    </li>
                    <li className="mb-3">
                      <i />
                      <h5>DevOps Engineers</h5>
                    </li>
                    <li className="mb-3">
                      <i />
                      <h5>Designers</h5>
                    </li>
                    <li className="mb-3">
                      <i />
                      <h5>Developers</h5>
                    </li>
                  </ul> */}
                  <ul className="custom-list-1">
									<li className="mb-2"><h5>Strategists</h5></li>
									<li className="mb-2"><h5>Architects</h5></li>
									<li className="mb-2"><h5>DevOps Engineers</h5></li>
                  <li className="mb-2"><h5>Designers</h5></li>
                  <li className="mb-2"><h5>Developers</h5></li>
							</ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-lg-6 pb-3 pb-lg-0 d-flex flex-column justify-content-center pr-lg-5">
              <h2 className="title-1">Dedicated Research Teams</h2>
              <p>
                We also employee dedicated research teams to ensure we stay
                ahead of the latest global technological trends and how they can
                best serve our customers.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={about3} alt="About Us" />
            </div>
          </div>

          <div className="leadership-section mt-3 w-100 d-flex align-items-center flex-column">
            <h2 className="title-1 w-100">Meet Our Leadership Team</h2>
            <div className="row pb-6 leadership-team">
              <div className="col-12">
                <div className="row mt-0 mt-md-5 justify-content-center">
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Indaka Raigama"
                        detail="Chief Executive Officer"
                        image={Indaka}
                        link="https://www.linkedin.com/in/indaka/"
                      />
                    </a>
                    {/* <UserModal
                      username="Indaka Raigama"
                      title="Chief Executive Officer"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                          name="Ravindra Samarasinghe"
                          detail="Chief Operating Officer"
                          image={Ravi}
                          link="https://www.linkedin.com/in/ravindra-samarasinghe/"
                        />
                    </a>
                    {/* <UserModal
                      username="Ravindra Samarasinghe"
                      title="Chief Operations Officer"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Cameron Shepherd"
                        detail="Chief Financial Officer"
                        image={Cam}
                        link="https://www.linkedin.com/in/cameron-shepherd-852202281/"
                      />
                    </a>

                    {/* <UserModal
                      username="Cameron Shepherd"
                      title="Chief Financial Officer"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Rashan Peiris"
                        detail="Chief Technology Officer"
                        image={Rashan}
                        link="https://www.linkedin.com/in/rashans/"
                      />
                    </a>

                    {/* <UserModal
                      username="Rashan Peiris"
                      title="Chief Technology Officer | Technology"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Malinda Sirisena"
                        detail="Director Operations APAC"
                        image={Malinda}
                        link="https://www.linkedin.com/in/malinda-sirisena-45230413/"
                      />
                    </a>
                    {/* <UserModal
                      username="Malinda Sirisena"
                      title="Director Operations APAC"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Garima Sharma"
                        detail="Manager Operations Mohali, India"
                        image={Garima}
                      />
                    </a>
                  </div>
                  {/* <div className="col-6 col-md-3 col-lg-3 mt-3">
                    <UserCard
                      name="Jigarjit Singh"
                      detail="Head of Operations, North India"
                      image={Jigar}
                    />
                  </div> */}
                  <div className="contact-card-section col-6 col-md-3 col-lg-3 mt-3">
                    <a className="contact-card">
                      <UserCard
                        name="Shiraz Thajudeen"
                        detail="Head of EMEA"
                        image={Shiraz}
                        link="https://www.linkedin.com/in/shirazthajudeen/"
                      />
                    </a>

                    {/* <UserModal
                      username="hiraz Thajudeen"
                      title="Head of EMEA"
                      userdetails1="As a member of the Board of Directors at Itelasoft (Pty) Ltd, I bring a wealth of experience and expertise to the table. With a proven track record in strategic leadership and business development, I am committed to driving the company towards continued growth and success."
                      userdetails2="Throughout my career, I have demonstrated a strong passion for innovation and a keen understanding of industry trends. I thrive in dynamic environments and am adept at navigating complex challenges to achieve positive outcomes."
                      userdetails3="At Itelasoft, I am dedicated to fostering a culture of collaboration and excellence. By leveraging my skills in decision-making and problem-solving, I am committed to guiding the company towards its strategic objectives and maximizing value for all stakeholders. I am honored to be a part of the Itelasoft team and look forward to contributing to its ongoing success."
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="grey-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="title-1">Our Values</h2>
              <p>
                We commit to our customer&apos;s success and pride ourselves not
                just on what we do, but how we do it.
              </p>
              <div className="row mt-5 justify-content-center">
                <ValueCard
                  title="Success is shared "
                  detail="Your success is our success and we're dedicated to delivering exceptional outcomes – every time."
                />
                <ValueCard
                  title="Quality & Communication "
                  detail="Our focus on quality and clear communication means that inefficiencies are minimised and productivity is increased."
                />
                <ValueCard
                  title="Team work"
                  detail="We are a tight knit and high performing team. When you partner with us, you become a valued member of our extended team "
                />
                <ValueCard
                  title="Integrity & Honesty "
                  detail="We act with integrity, always perform at our best and demonstrate leadership at all levels of our business "
                />
                <ValueCard
                  title="Accountability"
                  detail="We take the trust our customers put in us seriously and take responsibility not just for tasks, but instead for outcomes "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row py-6">
            <div className="col-12">
              <h2 className="title-1">Our Trusted Customers Worldwide</h2>
              <p>
                We work with companies of all sizes and from all corners of the
                globe, including Start Ups/Scale Ups, Mid-Size Companies and
                Large Enterprise.  Given the wide industry appeal and practical
                application for our services, we also don&apos;t limit ourselves
                to a narrow set of industries. Examples of some of the sectors
                we&apos;ve partnered with before include Retail Business,
                Education, FinTech and Telecommunications.
              </p>
              <Clients />
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-12">
              <OurPartners />
            </div>
          </div>
          <div className="row pb-6">
            <div className="col-12 pb-4">
              <h1 className="title-1">Get in touch with us</h1>
              <p>
                To speak to any of our experts about upcoming projects, complex
                problems to be solved or just to ask a few questions, please get
                in touch.
              </p>
            </div>
            <div className="col-lg-6">
              <ContactCard
                image={headoffice}
                hubname="Head office"
                location="Sydney, Australia"
                email="info@itelasoft.com"
                phonenumber1="1300 842 108 / +61 288 964 301"
                address="C/- Stone and Chalk Level 4, 11 York Street, Sydney, NSW, 2000, Australia"
              />
            </div>
            <div className="col-lg-6">
              <ContactCard
                image={colombohub}
                hubname="Development hub"
                location="Sri Lanka "
                phonenumber1="+94 112 099 900 / +94 112 099 966"
                address="No 69, Old Kottawa Road, Mirihana, Sri Lanka"
              />
            </div>
            <div className="col-lg-6">
              <ContactCard
                image={indiahub}
                hubname="Development hub"
                location="India "
                phonenumber1="+91 700 920 9673"
                address="Plot Number D 235 (P), 
                First Floor  Near Amazon Warehouse, 
                Phase 8B, 
                Industrial Area, 
                Mohali, 
                Sahibzada Ajit Singh Nagar, 
                Punjab, 
                India 
                "
              />
            </div>
            <div className="col-lg-6">
              <ContactCard
                image={UKhub}
                hubname="Operations"
                location="EMEA"
                phonenumber1="+44 7920 077135"
                address="128 City Road, London,
                EC1V 2NX,
                United Kingdom"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="row wrap-contact-us">
        <div className="container mt-7 mb-5">
          {/* <GetInTouch /> */}
          <div className="home-getting-touch">
            <div className="getting-touch">
              <GetInTouchbtn textdce={"Let’s talk!"} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="home-getting-touch">
          <div className="getting-touch">
            <GetInTouchbtn textdce={"Let’s talk!"} />
          </div>
        </div> */}
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "about-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg1: file(relativePath: { eq: "about1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg2: file(relativePath: { eq: "about2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg3: file(relativePath: { eq: "about3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg4: file(relativePath: { eq: "about4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default About;
